import { FunctionComponent } from 'react';
import { Feature } from './constants';
import { useSnackbar } from 'notistack';
import { getObjectKeys } from 'utils/getObjectKeys';
import { useExtendedIntl } from 'hooks/useExtendedIntl';
import { useFeatureFlags } from 'featureFlags/useFeatureFlags';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Stack, Switch, Typography } from '@mui/material';
import { useAuth } from 'components/App/AuthProvider';

export const FeatureFlagsList: FunctionComponent = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useExtendedIntl();
  const { session } = useAuth();
  const { featureFlags, toggleFeature, isFeatureEnabled, getFeatureTag } = useFeatureFlags();

  const featureIds = getObjectKeys(featureFlags);

  if (!featureIds.length) {
    return (
      <Typography variant="body1" color="textSecondary">
        {formatMessage({ id: 'feature-flags.no-features' })}
      </Typography>
    );
  }

  const toggleFeatureClick = (featureId: Feature) => {
    toggleFeature(featureId);

    enqueueSnackbar(formatMessage({ id: 'feature-flags.previews-updated' }), { variant: 'success' });
  };

  return (
    <Stack spacing={1.25}>
      {featureIds.map((featureId) => (
        <Stack key={featureId} direction="row" alignItems="flex-start" spacing={1}>
          <Switch edge="start" onClick={() => toggleFeatureClick(featureId)} checked={isFeatureEnabled(featureId)} />
          <Accordion
            elevation={0}
            disableGutters
            TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
            sx={{
              flex: 1,
              '&::before': { display: 'none' },
              '.MuiAccordionDetails-root': { p: 0 },
              '.MuiAccordionSummary-root': { p: 0, minHeight: 0 },
              '.MuiAccordionSummary-content': { alignItems: 'center', columnGap: 1.5, height: 38, m: 0 },
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body1">{formatMessage({ id: `feature-flags.feature.${featureId}` })}</Typography>
              {session?.user.isStaff && getFeatureTag(featureId)}
              <Divider variant="middle" sx={{ flex: 1, ml: 2.5, mr: 4 }} />
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" color="textSecondary">
                {formatMessage({ id: `feature-flags.feature-description.${featureId}` })}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Stack>
      ))}
    </Stack>
  );
};
